import React from 'react'
import Layout from "../../components/App/Layout"
import Navbar from "../../components/App/Navbar"
import PageBanner from '../../components/Common/PageBanner'
import Footer from "../../components/App/Footer"
import AboutUsContent from '../../components/solutii/echipe/AboutUsContent'
import WhyChooseUs from '../../components/solutii/echipe/WhyChooseUs'

const AboutUs = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Echipe dedicate"
                homePageText="Soluții"
                homePageUrl="/"
                activePageText="Echipe dedicate"
            />
            <AboutUsContent />
            <WhyChooseUs />
            <Footer />
        </Layout>
    );
}

export default AboutUs;