import React from 'react'
import starIcon from '../../../assets/images/star-icon.png'
import howItWork from '../../../assets/images/how-its-work.png'

const WhyChooseUs = () => {
    return (
        <section className="how-its-work-area ptb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="how-its-work-content">
                            <span className="sub-title">
                                <img src={starIcon} alt="banner" /> 
                                Clienții Ne Apreciază
                            </span>
                            <h2>De ce să lucrezi cu echipa noastră?</h2>
                            <p>Colegii noștri sunt profesioniști cu înaltă calificare și experiență, care au cunoștințe în diverse tehnologii și domenii de activitate.</p>
                            <div className="inner-box">
                                <div className="single-item">
                                    <div className="count-box">
                                        1
                                    </div>
                                    <h3>Echipe atent selecționate</h3>
                                    <p>Procesul nostru de selecție a candidaților ne asigură un nivel foarte ridicat de cunoștințe pentru toate persoanele implicate.</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        2
                                    </div>
                                    <h3>Multiple modele de colaborare</h3>
                                    <p>Adaptăm serviciile pentru a satisface nevoile și obiectivele specifice fiecărui client. Astfel contractările sunt personalizate în funcție de nevoile curente reale clienților.</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        3
                                    </div>
                                    <h3>Ne ocupăm de toate aspectele</h3>
                                    <p>Managerii noștri se asigură că toate aspectele colaborării sunt rezolvate: hardware corespunzător, monitorizarea performanței, transfer de cunoștințe, team building.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="how-its-work-image">
                            <img src={howItWork} alt="banner" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WhyChooseUs