import React from 'react'
import { Link } from 'gatsby'
import aboutImage from '../../../assets/images/about/about-img5.png'
import starIcon from '../../../assets/images/star-icon.png'
import icon4 from '../../../assets/images/icons/icon4.png'
import icon5 from '../../../assets/images/icons/icon5.png'
import icon6 from '../../../assets/images/icons/icon6.png'
import icon7 from '../../../assets/images/icons/icon7.png'
import shape1 from '../../../assets/images/shape/circle-shape1.png'
 
const AboutUsContent = () => {
    return (
        <section className="about-area ptb-100">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="about-image">
                            <img src={aboutImage} alt="banner" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="about-content">
                            <div className="content">
                                <span className="sub-title">
                                    <img src={starIcon} alt="banner" /> 
                                    Outsourcing
                                </span>
                                <h2>Creșteți capacitatea de livrare cu o echipă de software dedicată</h2>
                                <p>Să ții pasul cu cerințele dezvoltării tale de software este o provocare? O echipă dedicată ar putea fi răspunsul.</p>
                                <ul className="features-list">
                                    <li>
                                        <img src={icon4} alt="banner" />
                                        <h3>6 Ani</h3>
                                        <p>De funcționare</p>
                                    </li>
                                    <li>
                                        <img src={icon5} alt="banner" />
                                        <h3>30+</h3>
                                        <p>Colegi în echipă</p>
                                    </li>
                                    <li>
                                        <img src={icon6} alt="banner" />
                                        <h3>10</h3>
                                        <p>Media de ani de experiență</p>
                                    </li>
                                    <li>
                                        <img src={icon7} alt="banner" />
                                        <h3>48 de ore</h3>
                                        <p>Timp de livrare</p>
                                    </li>
                                </ul>
                                <p>V-ați săturat să încercați să faceți față cerințelor dezvoltării software-ului? Echipele noastre dedicate pot ajuta ca afacerea ta să ajungă la următorul nivel. Cu o echipă dedicată, veți avea posibilitatea de a vă personaliza soluțiile software, de a vă simplifica procesul de dezvoltare și de a vă mări capacitatea de livrare.</p>
                                <p>Lăsați-ne să vă ajutăm să deblocați întregul potențial al dezvoltării software-ului și să vă atingeți obiectivele de afaceri. Echipele noastre dedicate sunt aici să vă sprijine la fiecare pas.</p>
                                
                                <Link to="/contact" className="default-btn">
                                    <i className="flaticon-right"></i>Discută cu noi<span></span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="circle-shape1">
                <img src={shape1} alt="banner" />
            </div>

            <div className="container">
                <div className="about-inner-area">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="about-text">
                                <h3>Soluții</h3>
                                <p>Transformăm afacerile transformând tehnologia, câte o soluție pe rând.</p>
                                
                                <ul className="features-list">
                                    <li><i className="flaticon-tick"></i> Realizare de produse software</li>
                                    <li><i className="flaticon-tick"></i> Echipe dedicate</li>
                                    <li><i className="flaticon-tick"></i> Consultanță IT</li>
                                    <li><i className="flaticon-tick"></i> Cursuri specializare </li>
                                    <li><i className="flaticon-tick"></i> Recrutare IT </li>
                                </ul>
                            </div>
                        </div>
    
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="about-text">
                                <h3>Domenii</h3>
                                <p>Dezvoltăm și integrăm pe verticală soluții de digitalizare pentru diverse domenii.</p>
                                
                                <ul className="features-list">
                                    <li><i className="flaticon-tick"></i> Transport si logistică</li>
                                    <li><i className="flaticon-tick"></i> Fintech & banking</li>
                                    <li><i className="flaticon-tick"></i> Ospitalitate si turism</li>
                                    <li><i className="flaticon-tick"></i> Producție industrială</li>
                                    <li><i className="flaticon-tick"></i> Retail online</li>
                                </ul>
                            </div>
                        </div>
    
                        <div className="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                            <div className="about-text">
                                <h3>Tehnologii</h3>
                                <p>Stăpânim o arie largă de tehnologii pentru a fii flexibili și agili în abordarea proiectelor.</p>
                                
                                <ul className="features-list">
                                    <li><i className="flaticon-tick"></i> Java</li>
                                    <li><i className="flaticon-tick"></i> SAP</li>
                                    <li><i className="flaticon-tick"></i> C#</li>
                                    <li><i className="flaticon-tick"></i> SQL</li>
                                    <li><i className="flaticon-tick"></i> Javascript</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="circle-shape1">
                <img src={shape1} alt="banner" />
            </div>
        </section>
    )
}

export default AboutUsContent;